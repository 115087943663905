.App {
  text-align: center;
  height: 80%;
  height: 80vh;
  width: 100%;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-window {
  align-items: center;
  height: 500px;
  max-height: 90%;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.react-chat-container {
  margin: auto;
  margin-top: 20vh;
}

.login-button-wrapper {
  width: 300px;
  max-width: 80vw;
  margin: auto;
  margin-top: 50%;
}

.loading-wrapper {
  margin-left: 50%;
  margin-top: 50%;
}

.gallery {
  max-width: 80%;
  width: 500px;
  margin: auto;
  padding-bottom: 300px;
}

.logo {
  margin: auto;
  width: 50%;
}